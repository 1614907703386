/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Variables and Root Styles */
:root {
    /* Colors */
    --black: #333333;
    --white: #FFFFFF;
    --off-white: #F5F5F7;
    --border: #D2D2D2;
    --main: #1B4E9B;
    --main-accent: #1AA7FF;
    --green: #378b29;
    --green-accent: #74d680;
    --text-gray: #858585;
    /* Measurements */
    --gutter-width: 14%;
    --mobile-gutter-width: 7%;
    /* Animation */
    --transition-length: 500ms;
    /* Typography */
    --heading-size: 7rem;
    /* Mobile Typography */
    --mobile-heading-size: 4rem;
}

.App {
    font-family: 'Noto Sans', sans-serif;
}
